import React from 'react'
import {  Container, Navbar,Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom';


const Header = ({title}) => {
  return (
    <header  className='custom-bg'>
        <Navbar variant='dark'  expand='md'>
            <Container>
                <Navbar.Brand>{title}</Navbar.Brand>
                <Navbar.Toggle aria-controls='my-nav'/>
                <Navbar.Collapse id='my-nav'>
                <Nav className=' mx-auto fw-normal'>
                <Nav.Link as={Link} className='no-hover-nav-link' to="/">Home</Nav.Link>
          <Nav.Link as={Link} className='no-hover-nav-link' to="/about-us">About</Nav.Link>
          <Nav.Link as={Link} className='no-hover-nav-link' to="/contact">Contact</Nav.Link>
          <Nav.Link as={Link} className='no-hover-nav-link' to="/privacy-policy">Privacy Policy</Nav.Link>
          <Nav.Link as={Link} className='no-hover-nav-link' to="/terms-conditions">Terms Conditions</Nav.Link>
          <Nav.Link as={Link} className='no-hover-nav-link' to="/cancellation-refund-policy">Cancellation & Refund Policy</Nav.Link>
          <Nav.Link as={Link} className='no-hover-nav-link' to="/withdrawal-policy">Withdrawal Policy</Nav.Link>

                   
                </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </header>
  )
}

export default Header