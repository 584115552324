import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { FaMapMarkerAlt, FaPhoneAlt, FaRegBuilding } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

const Footer = ({title,companyName,email,number}) => {
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth" // This enables smooth scrolling
        });
      };
  return (
    <footer className='footer-bg'>
        <Container >
            <Row>
                <Col lg="5"><div className='fw-bold'>{title}</div>
                &nbsp;
                <p>{title} , is a real-time online rummy card game which fully owned by {companyName}.</p>
                   
                <p> We are a passionate team of designers and developers with years experience in the card game industry. Our aim is to provide you an awesome online experience on this sophisticated handmade product!
                </p>
                </Col>
               
                <Col lg="2"><div className='fw-bold'>Policies Links</div>
                &nbsp;
                <ul className="list-unstyled footer-links">
                    <li>
                        <Link onClick={scrollToTop} to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                        <Link onClick={scrollToTop} to="/terms-conditions">Terms Conditions</Link>
                    </li>
                    <li>
                        <Link onClick={scrollToTop} to="/cancellation-refund-policy">Cancellation & Refund Policy</Link>
                    </li>
                    <li>
                        <Link onClick={scrollToTop} to="/withdrawal-policy">Withdrawal Policy</Link>
                    </li>
                </ul>


                </Col>
                <Col lg="5" ><div className='fw-bold'>Get in Touch</div>
                &nbsp;
                <ul className="list-unstyled footer-links">
                    <li>
                        <a href="#"><FaRegBuilding className="me-3" />{companyName}</a>
                    </li>
                    <li>
                        <a href="#"><FaMapMarkerAlt className="me-3" />Ground Floor, 3-29/8 15, Vidyanagar, Derabail, Mangaluru, Dakshina Kannada, Karnataka, 575006</a>
                    </li>
                    <li>
                        <a href="#"><MdEmail className="me-3" />{email}</a>
                    </li>
                    <li>
                        <a href="#"><FaPhoneAlt className="me-3" />{number}</a>
                    </li>
                </ul>
                </Col>

            </Row>
            <Row></Row>
        </Container>
    </footer>
  )
}

export default Footer