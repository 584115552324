import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import image1 from '../images/20230215_1305_63ec8b4854d88.827bc628055b009e3bba.jpg'
import image2 from '../images/20230907_1142_64f969d576857.35c3014f077d3bb81c9b.png'
import image3 from '../images/20230907_1142_64f969d577393.01a01f5995b3d76f380d.png'
import DownloadBtn from './DownloadBtn'


const Home = ({title,email}) => {
  return (
    <div>
      <div className='rummy-img'>
       <div className='text'>
       <h1>JOIN {title} <br/> AND RECEIVE A DAILY CASH BONUS.</h1>
       <p className='align-items-right'> {title}</p>
       <DownloadBtn title ="Download the App" btnClass = "download-btn" iconEnable={true} iconClass="icon-main"/>
       </div>
     
     
      </div>
     
        {/* <img src={rummyImg} className='rummy-img' /> */}
        &nbsp;
        <Container>
        <Row>
            <Col lg="4" className=' text-uppercase title-font-size'>
            <div>{title}</div>
            <div>Play {title} for a chance to win real cash prizes in Indian rupees.</div>
            </Col>
            <Col lg="8">
           <div>{title} is online Rummy portal , We providing best-in-class gaming experience to all its users. Our aim is to give every player a premium and hassle-free rummy-playing experience on our android and iOS app. Our games are designed in a manner that anybody can play without facing any difficulties.</div> 
           &nbsp;
           <div>{title} is on a mission to promote Rummy & {title} as a professional game and create a community of skilled gamers. Rummy & {title} is a game of skill as opposed to games of chance. It is involves skill to win.</div>
           &nbsp;<div>
            We at {title} understand the needs of online gaming in India and strive to provide better and faster results through our software. Our software is built on a custom built architecture to be robust in operating Multi-Player Tables and Online Tournaments</div>
            </Col>
        </Row>
        </Container>
        &nbsp;
        <Container>
        <Card>
      <Card.Header>Unique Features of {title}</Card.Header>
      <Card.Body>
       
        <Card.Text>
         <p>{title} gives you the highest level of security to play online games on the platform. All the information you share with us is encrypted and remains secure and confidential with us. Also, our unique algorithm ensures that every game on our platform is fair and all that matters is your game skills.</p>
         <p >These are some of the unique security features of {title}:</p>
         <p><Row>
            <Col>
           
            <strong>
            Key features:</strong> &nbsp;
            No download, Seamless Gaming Experience, newest Technology </Col>
            </Row></p>           
            <p><Row>
            <Col>
            <strong>
            Multi-platform Compatibility:</strong> &nbsp;
            iOS, Windows, and Android</Col>
            </Row></p>
          
            <p><Row>
            <Col>
            <strong>
            24×7 customer support:</strong> &nbsp;
            Our technology champions are available 24 hours to help you with any problems you may have. Players can connect with us by email at {email}  with a response time of three hours. For club players, direct telephone support is also available</Col>
            </Row></p>
        </Card.Text>
        
      </Card.Body>
    </Card>
    &nbsp;
        </Container>
        <Container>
            <Row>
                <Col ><img src={image1} width={400} height={250}/></Col>
                <Col ><img src={image2} width={400} height={250}/></Col>
                <Col ><img src={image3} width={400} height={250}/></Col>
            </Row>
        </Container>
        &nbsp;
        
    </div>
  )
}

export default Home