import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'

const About = ({title}) => {
  return (
    <div>
        &nbsp;
        <Container>
            <Row >
                <Col>
                <h3 className='d-flex justify-content-center'>About us</h3>

<p>{title} is an India-based online gaming website, that provides the players a platform to play online 13-card Indian rummy games. {title} is the best rummy site in India &amp; an authentic provider of Indian online rummy. We are here to provide you with the ultimate 13-card rummy experience and an opportunity to win bundles of real cash. We possess unique features to provide our players with an unimaginable gaming experience, and we always strive to deliver the Best Rummy Experience to our players. Playing at Deccan Rummy offers entertainment as well as monetary gains.</p>

<p><strong>Who we are?</strong></p>

<p>{title}.in is an online rummy gaming portal owned by Rewardoo Private Limited. We are committed to holding ourselves to the highest ethical standards.</p>

<p>We have a dedicated team with vast experience in the online gaming industry. The robust gaming design you see is built by designers with several years of experience. We have set incredibly high standards in the gaming industry, and we are always pushing our limits to provide a stunning platform for the community of online players. Play rummy games with passion and vigour and win real cash.</p>

<p>{title} provides an engaging online platform where the 13-card Indian rummy can be enjoyed at leisure. With 100,000+ players in your user base, we provide a zero-waiting hand policy where players are engaged within the shortest possible duration. Our online rummy platform is built on cutting-edge technology with a high-definition user interface that provides the ultimate Indian rummy experience. Play rummy online with us and see the magic yourself.</p>

<p><strong>{title}&nbsp;has been</strong></p>

<ul>
	{/* <li><strong>Certified from InfySec Security labs for fair play and random number generator (RNG).</strong></li> */}
	<li><strong>Tied up with multiple banks and payment gateways to process payments from customers.</strong></li>
	<li><strong>Comodo SSL is secured to provide the highest possible encryption levels for online transactions.</strong></li>
</ul>

<p>The Learning Experience: Beginning to play online rummy is fun, and playing continuously gives you an edge on different types of combinations. Our unique rummy learning program helps you bring about parallel thoughts and create the best possible hands to win. Deccan Rummy provides all unique card combinations in the 13-card rummy that helps you hone your skills.</p>

<p>The Secure Platform: We at {title} maintain high confidentiality of all our client information. All your transactions within the platform are safe and secure with triple-layer security.</p>

<p>Legality and Responsibility: Playing Rummy is legal in India. Supreme Court of India has declared Rummy legal as Rummy is considered a game that involves skill, rather than chance.</p>

<p>&nbsp;</p>

<p><strong>How do we differ from other online Rummy sites?</strong></p>

<p>Our Rummy games create a good immersion into the game through the accelerated full-screen HTML5 graphics. Most other top rummy sites are flash-based. Our games are much faster than other rummy sites, and our user interface will mesmerize you with an eye-pleasing design. You need a simple browser to play our rummy games in our highly secure gaming environment. We offer 24/7 chat assistance, so our players can reach us anytime, for any assistance using our online chat facility. We provide unlimited bonus options to our players for a lifetime with easy withdrawal options.</p>
             
           </Col>
            </Row>
        </Container>
    </div>
  )
}

export default About