import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const WithDrawalPolicy = ({title,email,url}) => {
  return (
    <div>&nbsp;
        <Container>
        <Row>
            <Col>
            <p>{title} has a total and unwavering commitment to the safety and security of player funds. Player balances are kept in segregated accounts and not used for operational expenses. This ensures that your funds are always available for you to cash out in accordance with our banking and security procedures.</p>
<p>The minimum withdrawal request at a given point of time is Rs. 100 and the {title} is Rs. 10,000. This is mandatory in order to encourage responsible play as well as to prevent unauthorized or illegal activity.</p>
<p>{title} gives away two free withdrawals in a month. All subsequent request for cash-out will be charged with 2% – 4% of the cash out amount as the service charges and the applicable TDS, which will be deducted from your account. If you do not have an adequate amount to charge the processing fees, your cash-outs will not be processed. You may resend a request in the succeeding month to avail free cash-out.</p>
<p>In the event of game play for Cash Games, related Buy-in amount will be deducted from Player’s ‘Cash Balance’. If the game play Buy-in amount is more than the ‘Non-Withdrawable Balance’ amount in the Player’s Cash Balance, then the remaining amount will be deducted from Player’s ‘Withdrawable Balance’. In the event the Player decides to leave the game play table and is eligible for credit of game play amount, then the game play Buy-in amount will be credited back in the same proportion, to the Non-Withdrawable Balance and Withdrawable Balance, as it was deducted earlier.</p>
<p>If the Player plays the Game and wins, the Winning amount will be reflected in the Player’s Withdrawable Balance and will be available for Withdrawal by the Player.</p>
<p>Withdrawal without game play will generally not be entertained, however in some cases, 5% of the deposited amount will be charged to process the Withdrawal.</p>
<p>If the account designated to receive your withdrawal has any issues, please cancel your withdrawal request and email us at DSyeet.service@gmail.com. In order to protect against collusion and fraud, a 48-hour delay is imposed on cashing out from your last deposit. 
Therefore, please allow 48 hours from your last deposit before requesting your withdrawal.</p>
<p>How to Make a Withdrawal Request?</p>
<p>To make a withdrawal request, log in to your {title} and click on Withdrawal tab. If your profile is complete and all mandatory documents submitted and verified, you will get the options for withdrawal, namely Bank Transfer.</p>
<p>Modes of Withdrawal</p>
<p>Bank Transfer Transfer is a withdrawal method that allows you to receive your winnings/funds by having them credited directly to your bank account. To make a withdrawal request via Bank Transfer, login to your {title} account, click the ‘Withdrawal’ tab and then click on Bank Transfer. Fill in the amount you wish to withdraw, if this is your first withdrawal by Bank Transfer, in the next field, you will have to give your registered account number and fill in details of the bank in which you wish to transfer. Bank account no., Type of account, Bank Name, Branch details, IFSC Code and MIRC Code are some of the details you will need to submit to initiate Bank Transfer. Once these details are submitted, they cannot be edited, fill in the details carefully.</p>
<p>If your bank details need to be changed, it can be done with the help of our support team, who will also verify the details. You will have to call and submit the required documents to make the changes. Note: Your Bank account needs to comply with the following conditions:</p>
<p>The bank account must be in your name.</p>
<p>The state mentioned in your Bank statement, must match the registered state in your {title} Account. It may take up to 48 hours for {title} to process your withdrawal request and another 2 business days for funds to be credited to your designated bank account.</p>
<p>There are no fees charged by {url}, for using ‘Bank Transfer’ up to Two times in a month. However, an additional charge of 15.00 INR per transaction is charged by us for subsequent withdrawals in one month. Players are responsible for any fees charged by their bank or intermediary bank (if applicable) for receiving bank Transfers. For more information on your bank’s policy concerning fees for receiving Bank Transfers, please contact your bank directly.</p>
<p>*All our successful withdrawals are processed on Daily basis except Sundays and Bank Holidays.</p>
<p>For any clarifications please feel free to write to us at {email}.</p>
            </Col>
        </Row>
        </Container>
        &nbsp;</div>
  )
}

export default WithDrawalPolicy