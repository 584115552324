import { Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./components/Home";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsConditions from "./components/TermsConditions";
import Cancellation from "./components/Cancellation";
import WithDrawalPolicy from "./components/WithDrawalPolicy";
import Contact from "./components/Contact";
import About from "./components/About";

function App() {
  return (
    <div className="App">
      <Header title={'Moth Rummy'}/>
      <Routes>
        <Route path="/" element={<Home title={'Moth Rummy'} email={' motherindiaventure@gmail.com'}/>}></Route>
        <Route path = "/about-us" element= {<About title={'Moth Rummy'}/>}></Route>
        <Route path = "/contact" element= {<Contact companyName={'MOTHER INDIA VENTURE'} email={' motherindiaventure@gmail.com'} number={'+91 895 161 4301'}/>}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy title={'Moth Rummy'} email={' motherindiaventure@gmail.com'} url={'https://mothrummy.in'}/>}></Route>
        <Route path="/terms-conditions" element={<TermsConditions title={'Inaya Rummy'} companyName={'MOTHER INDIA VENTURE'} email={' motherindiaventure@gmail.com'} number={'+91 895 161 4301'}/>}></Route>
        <Route path="/cancellation-refund-policy" element={<Cancellation  email={' motherindiaventure@gmail.com'} />}></Route>
        <Route path="/withdrawal-policy" element={<WithDrawalPolicy title={'Moth Rummy'}  email={' motherindiaventure@gmail.com'} url={'https://mothrummy.in'}/>}></Route>
      </Routes>
      <Footer title={'Moth Rummy'} companyName={'MOTHER INDIA VENTURE'} email={' motherindiaventure@gmail.com'} number={'+91 895 161 4301'}/>
    </div>
  );
}

export default App;
